import React, { useState, useEffect } from 'react';
import Footer from '../components/Footer';
import emailjs from 'emailjs-com';
import { useUser } from '@clerk/clerk-react';

const Help = () => {
  const { user } = useUser(); // Récupère l'utilisateur connecté via Clerk

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    category: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Préremplissage des champs avec les données utilisateur
  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        name: `${user.firstName} ${user.lastName}`,
        email: user.emailAddresses[0]?.emailAddress || '',
      });
    }
    document.title = "Centre D'aide - LEYZEN™";
  }, [user, formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !formData.name ||
      !formData.email ||
      !formData.category ||
      !formData.message
    ) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    const templateParams = {
      name: formData.name,
      email: formData.email,
      category: formData.category,
      message: formData.message,
    };

    try {
      const serviceID = 'service_slr41ao'; // Service ID EmailJS
      const templateID = 'template_2b0wduu'; // Template ID EmailJS
      const userID = 'CE1YyF746wpoOLJc9'; // User ID EmailJS

      await emailjs.send(serviceID, templateID, templateParams, userID);

      setSuccessMessage('Votre demande a été envoyée avec succès !');
      setErrorMessage('');
      setFormData({ ...formData, category: '', message: '' }); // Réinitialisation partielle
    } catch (error) {
      console.error("Erreur lors de l'envoi :", error);
      setErrorMessage(
        "Une erreur s'est produite. Veuillez réessayer plus tard.",
      );
    }
  };

  return (
    <div className="min-h-screen bg-[#161616] text-white">
      <div className="max-w-4xl w-full px-6 py-12 mx-auto">
        <h1 className="text-3xl font-alientz text-center mb-6">
          Centre d'aide
        </h1>
        <p className="text-center text-gray-400 mb-10">
          Nous sommes là pour vous aider. Si vous avez une question, remplissez
          le formulaire ci-dessous et nous vous répondrons rapidement.
        </p>

        {/* Messages de succès ou d'erreur */}
        {successMessage && (
          <div className="mb-6 p-4 text-green-500 bg-green-100 rounded-lg text-center">
            {successMessage}
          </div>
        )}
        {errorMessage && (
          <div className="mb-6 p-4 text-red-500 bg-red-100 rounded-lg text-center">
            {errorMessage}
          </div>
        )}

        <form
          onSubmit={handleSubmit}
          className="bg-[#111111] text-[#AAAFB7] p-8 rounded-3xl shadow-lg"
        >
          <div className="mb-6">
            <label htmlFor="name" className="block text-lg font-alientz">
              Nom complet
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              readOnly // Rendre le champ non modifiable
              className="w-full p-3 mt-2 bg-[#0A0A0A] text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              required
              aria-label="Nom complet (lecture seule)"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="email" className="block text-lg font-alientz">
              Email étudiant
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              readOnly // Rendre le champ non modifiable
              className="w-full p-3 mt-2 bg-[#0A0A0A] text-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              required
              aria-label="Email étudiant (lecture seule)"
            />
          </div>

          <div className="mb-6 font-bold">
            <label htmlFor="category" className="block text-lg font-alientz">
              Catégorie de votre demande
            </label>
            <select
              id="category"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full p-3 mt-2 bg-[#0A0A0A] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              required
              aria-label="Sélectionnez une catégorie"
            >
              <option value="" disabled>
                Choisissez une catégorie
              </option>
              <option value="Problème technique">Problème technique</option>
              <option value="Question sur la formation">
                Question sur la formation
              </option>
              <option value="Demande de certificat">
                Demande de certificat
              </option>
              <option value="Autre">Autre</option>
            </select>
          </div>

          <div className="mb-6">
            <label htmlFor="message" className="block text-lg font-alientz">
              Message détaillé
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full p-3 mt-2 bg-[#0A0A0A] text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white"
              rows="6"
              required
              aria-label="Entrez les détails de votre demande"
            />
          </div>

          <button
            type="submit"
            className="w-full p-3 border-2 bg-gray-300 text-black font-alientz rounded-full hover:bg-black hover:text-white transition duration-300"
          >
            Envoyer la demande
          </button>
        </form>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Help;
