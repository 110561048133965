import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CoreLuxeNav from '../../components/CoreLuxeNav';
import Footer from '../../components/Footer';

const Tendances = () => {
  useEffect(() => {
    document.title = 'Tendances - LEYZEN™';
  }, []);

  return (
    <div>
      <CoreLuxeNav />
      <section className="p-8 mb-6">
        <h1 className="text-4xl font-alientz text-center mb-6">
          Les Tendances du Luxe
        </h1>
        <p className="mb-6">
          Le secteur du luxe est en constante évolution, influencé par des
          changements sociaux, technologiques et économiques. Ce module vous
          fera découvrir les tendances actuelles qui façonnent l'avenir du luxe.
        </p>
      </section>

      <section className="bg-black p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Le Luxe Éthique et Durable
        </h2>
        <p className="mb-6">
          L’une des tendances majeures dans le luxe moderne est l’importance
          croissante des valeurs éthiques et durables. Les consommateurs
          demandent des produits fabriqués de manière responsable, avec un
          impact minimal sur l’environnement. Des marques comme{' '}
          <strong>Patagonia</strong> et <strong>Stella McCartney</strong>{' '}
          montrent la voie en intégrant des pratiques durables dans leurs
          processus de fabrication.
        </p>
        <p className="mb-6">
          Les clients de luxe sont désormais de plus en plus conscients de
          l'origine des matériaux, des conditions de travail dans les ateliers,
          et de l'empreinte carbone des produits qu'ils achètent. Les marques
          qui prennent en compte ces préoccupations renforcent leur réputation
          et répondent à une demande croissante pour des produits plus
          responsables.
        </p>
      </section>

      <section className="p-8 mb-4">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Digitalisation et Luxe
        </h2>
        <p className="mb-6">
          Le secteur du luxe a pris le virage numérique ces dernières années.
          L'expérience d'achat en ligne est désormais un aspect crucial de
          l'expérience de luxe. Les marques de luxe investissent dans des sites
          web et des applications mobiles ultra-performants, offrant un service
          client de haut niveau même sur des plateformes digitales. L’interface
          utilisateur, la rapidité de livraison et la qualité de l’expérience
          sont des facteurs essentiels pour satisfaire les clients exigeants.
        </p>
        <p>
          De plus, des technologies comme la réalité augmentée (AR) et la
          réalité virtuelle (VR) permettent aux clients de découvrir des
          produits de luxe de manière immersive avant de faire un achat. Ces
          technologies permettent aux consommateurs de visualiser des articles
          dans un contexte réel, de les essayer virtuellement, ou même de
          participer à des événements virtuels exclusifs.
        </p>
      </section>

      <section className="bg-black p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Les Influenceurs et le Luxe
        </h2>
        <p className="mb-6">
          Les influenceurs jouent un rôle majeur dans la promotion des marques
          de luxe, en particulier sur les plateformes comme Instagram, TikTok et
          YouTube. Les marques de luxe s'associent de plus en plus à des
          influenceurs de renom pour atteindre des audiences plus jeunes et
          diversifiées. Ces partenariats permettent aux marques de créer des
          campagnes authentiques et de toucher des consommateurs plus engagés
          qui recherchent un lien personnel avec leurs marques préférées.
        </p>
        <p className="mb-6">
          Les collaborations entre marques de luxe et influenceurs offrent une
          visibilité inédite et un marketing plus naturel. Les influenceurs,
          souvent perçus comme des experts dans des domaines spécifiques,
          peuvent aider les marques à se rapprocher de leurs consommateurs tout
          en préservant l'image de luxe.
        </p>
      </section>

      <section className="p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Le Luxe Expérientiel
        </h2>
        <p className="mb-6">
          De plus en plus de consommateurs recherchent des expériences de luxe
          au-delà du produit lui-même. Les voyages exclusifs, les événements
          privés, et les rencontres personnalisées avec les créateurs ou les
          ambassadeurs de la marque deviennent des éléments essentiels de
          l'offre des marques de luxe. Ces expériences permettent aux clients de
          vivre une immersion totale dans l'univers de la marque, renforçant
          leur fidélité et leur engagement.
        </p>
        <p className="mb-6">
          Le luxe expérientiel inclut également des services sur mesure, comme
          des ateliers de personnalisation, des visites privées de lieux de
          production, ou des invitations à des événements comme des défilés de
          mode. Ces services personnalisés apportent un sentiment de rareté et
          d'exception, caractéristiques du luxe.
        </p>
      </section>

      <section className="bg-black p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Luxe et Intelligence Artificielle
        </h2>
        <p className="mb-6">
          L'intelligence artificielle (IA) fait son entrée dans le secteur du
          luxe, non seulement pour améliorer l'expérience client, mais aussi
          pour optimiser la gestion des stocks, personnaliser les
          recommandations et prévoir les tendances. L’IA est utilisée pour
          analyser les données clients et fournir des suggestions personnalisées
          qui correspondent aux goûts et aux préférences des consommateurs.
        </p>
        <p className="mb-6">
          Les chatbots et assistants virtuels alimentés par l'IA sont de plus en
          plus utilisés pour offrir une expérience de service client 24/7. Ces
          technologies permettent aux marques de rester connectées à leurs
          clients, d'anticiper leurs besoins, et de créer une expérience d'achat
          encore plus fluide et adaptée.
        </p>
      </section>

      <section className="p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Luxe et Innovation Technologique
        </h2>
        <p className="mb-6">
          L’innovation technologique continue de transformer le secteur du luxe.
          Les marques utilisent des technologies de pointe pour créer des
          produits plus performants, plus sûrs, et plus esthétiques. Par
          exemple, l'utilisation de nouveaux matériaux, de tissus intelligents,
          et de techniques de fabrication avancées permet de répondre aux
          attentes des consommateurs tout en offrant des produits d'une qualité
          sans compromis.
        </p>
        <p className="mb-6">
          Les innovations dans la fabrication, comme l'impression 3D, ouvrent
          également de nouvelles possibilités dans la conception de produits de
          luxe uniques et personnalisés. Cette capacité à créer des objets sur
          mesure permet aux marques de répondre à la demande d'exclusivité tout
          en restant à la pointe de la technologie.
        </p>
      </section>

      <section className="bg-black p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Les Marques de Luxe et l'Engagement Social
        </h2>
        <p className="mb-6">
          L'engagement social est devenu un élément clé de la stratégie des
          marques de luxe. Les consommateurs, en particulier les plus jeunes,
          attendent des marques qu'elles soient responsables, non seulement en
          termes de durabilité, mais aussi en matière d'engagement social et
          éthique. Les marques de luxe intègrent de plus en plus des pratiques
          responsables et soutiennent des causes telles que les droits de
          l'homme, l'égalité des sexes et la protection de l'environnement.
        </p>
        <p className="mb-6">
          Cela inclut la réduction de l'empreinte écologique des produits, le
          soutien aux artisans locaux, et l'investissement dans des initiatives
          sociales. Cet engagement renforce la confiance des consommateurs et
          participe à la construction d'une image de marque positive, fondée sur
          des valeurs humaines.
        </p>
      </section>

      <section className="p-8 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Le Luxe et la Personnalisation
        </h2>
        <p className="mb-6">
          La personnalisation est devenue l'une des caractéristiques les plus
          recherchées dans le secteur du luxe. Les clients ne veulent plus
          seulement des produits haut de gamme, ils veulent des produits qui
          reflètent leur identité. De plus en plus de marques de luxe offrent
          des options de personnalisation pour leurs produits, allant des
          articles de mode aux accessoires en passant par les voitures et les
          montres.
        </p>
        <p className="mb-6">
          Cette tendance à la personnalisation s'étend également aux services.
          Les clients peuvent désormais vivre des expériences sur mesure, qu'il
          s'agisse de voyages, de soins ou d'événements exclusifs. Cela permet
          aux marques de se distinguer par une approche client
          ultra-personnalisée, répondant aux désirs uniques de chaque
          consommateur.
        </p>
      </section>

      <section className="bg-gradient-to-b from-black to-[#121212] p-8">
        <h2 className="text-3xl font-alientz text-center mb-4 text-white">
          Passer Au Test
        </h2>
        <p className="mb-6 text-gray-300 text-center">
          Testez vos connaissances sur cette introduction au luxe et découvrez
          combien vous en savez sur ce secteur en constante évolution !
        </p>
        <div className="flex justify-center">
          <Link
            to="/core/quiz"
            className="bg-black text-white font-bold shadow shadow-white px-6 py-3 rounded-2xl shadow-3xl hover:bg-[#121212] hover:scale-105 transition-transform duration-400"
          >
            Commencer le Quiz
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Tendances;
