export const questions = [
  {
    id: 1,
    question: 'Quelle est la caractéristique principale du luxe moderne ?',
    options: [
      "L'exclusivité",
      'Le prix élevé',
      'La publicité massive',
      'La production de masse',
    ],
    correctAnswer: 0,
  },
  {
    id: 2,
    question: 'Quel élément est essentiel dans le service client de luxe ?',
    options: [
      'La rapidité',
      'La personnalisation',
      'Les prix bas',
      'La standardisation',
    ],
    correctAnswer: 1,
  },
  {
    id: 3,
    question:
      "Quelle tendance majeure influence le secteur du luxe aujourd'hui ?",
    options: [
      'La production de masse',
      'Le luxe éthique et durable',
      'La réduction des prix',
      'La simplicité',
    ],
    correctAnswer: 1,
  },
  {
    id: 4,
    question:
      'Quelle caractéristique définit le mieux les nouveaux consommateurs de luxe ?',
    options: [
      "L'âge avancé",
      "La recherche d'authenticité",
      "L'indifférence aux valeurs",
      'Le désintérêt pour le digital',
    ],
    correctAnswer: 1,
  },
  {
    id: 5,
    question: 'Quel est le rôle principal des influenceurs dans le luxe ?',
    options: [
      'Réduire les coûts',
      'Créer des liens authentiques',
      'Augmenter la production',
      "Standardiser l'offre",
    ],
    correctAnswer: 1,
  },
  {
    id: 6,
    question:
      "Comment le luxe utilise-t-il principalement l'intelligence artificielle ?",
    options: [
      'Pour remplacer le service client',
      "Pour personnaliser l'expérience",
      'Pour réduire les coûts',
      'Pour éliminer les emplois',
    ],
    correctAnswer: 1,
  },
  {
    id: 7,
    question:
      'Quelle est la stratégie clé des marques de luxe pour maintenir leur exclusivité ?',
    options: [
      'La rareté contrôlée',
      'La production massive',
      'Les prix bas',
      'La disponibilité permanente',
    ],
    correctAnswer: 0,
  },
  {
    id: 8,
    question:
      'Quel aspect est crucial dans la communication des marques de luxe ?',
    options: [
      'La quantité',
      'Le storytelling',
      'La simplicité',
      'La répétition',
    ],
    correctAnswer: 1,
  },
  {
    id: 9,
    question:
      'Comment les marques de luxe gèrent-elles principalement leur présence digitale ?',
    options: [
      "En l'ignorant",
      'En créant des expériences immersives',
      "En limitant l'accès",
      'En réduisant les coûts',
    ],
    correctAnswer: 1,
  },
  {
    id: 10,
    question:
      "Quelle est la principale motivation d'achat des produits de luxe ?",
    options: [
      'Le prix bas',
      'Le statut social',
      'La disponibilité',
      'La simplicité',
    ],
    correctAnswer: 1,
  },
];
