import { motion } from 'framer-motion';
import { Crown } from 'lucide-react';

const QuizCard = ({
  question,
  selectedAnswer,
  onSelectAnswer,
  isAnswered,
  showCorrectAnswer,
}) => {
  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0, y: 50 }}
      animate={{ scale: 1, opacity: 1, y: 0 }}
      exit={{ scale: 0.8, opacity: 0, y: -50 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      className="relative w-full max-w-2xl bg-black rounded-2xl shadow-2xl p-8 border border-zinc-800"
      style={{
        boxShadow: '0 0 40px rgba(0, 0, 0, 0.8)',
      }}
    >
      <div className="absolute -bottom-2 -right-2 w-full h-full bg-black rounded-xl -z-10 border border-zinc-800" />
      <div className="absolute -bottom-4 -right-4 w-full h-full bg-black rounded-xl -z-20 border border-zinc-800" />

      <div className="flex items-center gap-3 mb-8">
        <Crown className="w-8 h-8 text-purple-400" />
        <h2 className="text-2xl font-bold text-white">{question.question}</h2>
      </div>

      <div className="space-y-4">
        {question.options.map((option, index) => (
          <motion.button
            key={index}
            whileHover={{
              scale: 1.02,
              backgroundColor: 'rgba(139, 92, 246, 0.1)',
            }}
            whileTap={{ scale: 0.98 }}
            onClick={() => !isAnswered && onSelectAnswer(index)}
            className={`w-full p-4 text-left rounded-lg transition-all ${
              isAnswered
                ? index === question.correctAnswer
                  ? 'bg-green-900/20 border-2 border-green-500 text-green-300'
                  : index === selectedAnswer
                    ? 'bg-red-900/20 border-2 border-red-500 text-red-300'
                    : 'bg-zinc-900/50 text-zinc-400'
                : selectedAnswer === index
                  ? 'bg-purple-900/20 border-2 border-purple-500 text-purple-300'
                  : 'bg-zinc-900/50 hover:bg-zinc-800/50 text-white'
            }`}
          >
            <span className="text-lg">{option}</span>
          </motion.button>
        ))}
      </div>
    </motion.div>
  );
};

export default QuizCard;
