import { motion } from 'framer-motion';
import { Trophy } from 'lucide-react';

const ScoreCard = ({ score, totalQuestions, onRestart }) => {
  const percentage = (score / totalQuestions) * 100;

  return (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      className="w-full max-w-2xl bg-black rounded-xl shadow-2xl p-8 text-center border border-zinc-800"
      style={{
        boxShadow: '0 0 40px rgba(0, 0, 0, 0.8)',
      }}
    >
      <div className="flex justify-center mb-6">
        <Trophy className="w-16 h-16 text-yellow-400" />
      </div>
      <h2 className="text-3xl font-bold text-white mb-4">Quiz Terminé !</h2>
      <p className="text-xl mb-6 text-zinc-300">
        Votre score : <span className="font-bold text-purple-400">{score}</span>{' '}
        sur {totalQuestions}
      </p>
      <div className="w-full bg-zinc-900 rounded-full h-4 mb-6">
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${percentage}%` }}
          transition={{ duration: 1 }}
          className="bg-purple-500 h-4 rounded-full"
        />
      </div>
      <button
        onClick={onRestart}
        className="bg-purple-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-purple-700 transition-all hover:scale-105"
      >
        Recommencer le Quiz
      </button>
    </motion.div>
  );
};

export default ScoreCard;
