import { motion } from 'framer-motion';

const ProgressBar = ({ currentQuestion, totalQuestions, score }) => {
  return (
    <div className="w-full max-w-2xl mx-auto mb-8">
      <div className="flex justify-between items-center text-zinc-400 mb-2">
        <span className="text-lg">
          Question {currentQuestion + 1}/{totalQuestions}
        </span>
        <span className="text-lg">
          Score: <span className="text-purple-400">{score}</span>
        </span>
      </div>
      <div className="w-full bg-zinc-900 h-2 rounded-full">
        <motion.div
          initial={{ width: 0 }}
          animate={{
            width: `${((currentQuestion + 1) / totalQuestions) * 100}%`,
          }}
          className="bg-purple-500 h-2 rounded-full"
        />
      </div>
    </div>
  );
};

export default ProgressBar;
