import { useEffect } from 'react';
import CoreLuxeNav from '../../components/CoreLuxeNav';
import Footer from '../../components/Footer';

const LesConsommateurs = () => {
  useEffect(() => {
    document.title = 'Les Consommateurs - LEYZEN™';
  }, []);

  return (
    <div>
      <CoreLuxeNav />
      <section className="p-6">
        <h1 className="text-3xl font-alientz text-center mb-6">
          Les Consommateurs de Luxe
        </h1>
        <p className="mb-6">
          Comprendre les consommateurs de luxe est essentiel pour créer une
          expérience qui résonne avec eux. Ce module explore les motivations,
          les comportements et les attentes des consommateurs dans l'univers du
          luxe.
        </p>
      </section>

      <section className="bg-black p-6 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Le Profil du Consommateur de Luxe
        </h2>
        <p className="mb-6">
          Le consommateur de luxe est souvent perçu comme étant un individu avec
          un pouvoir d'achat élevé, mais son profil va bien au-delà de cette
          simple caractéristique. Ce sont des personnes qui recherchent des
          expériences exclusives, une qualité irréprochable, et qui sont souvent
          à la recherche de produits ou services qui renforcent leur statut
          social.
        </p>
        <p className="mb-6">
          Les consommateurs de luxe sont aussi de plus en plus jeunes et
          diversifiés. L'essor des générations millénaire et Z a fait évoluer le
          secteur, influençant les stratégies des marques de luxe pour répondre
          à une demande de plus en plus technologique et éthique.
        </p>
      </section>

      <section className="p-4 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Les Motivations d'Achat des Consommateurs
        </h2>
        <ul className="list-disc pl-6 mb-6">
          <li>
            <strong>Le statut et la reconnaissance :</strong> L'achat de
            produits de luxe permet souvent au consommateur de marquer son
            appartenance à un groupe élite et d'être reconnu par ses pairs.
          </li>
          <li>
            <strong>La recherche d'une expérience unique :</strong> Les
            consommateurs de luxe veulent des expériences qui les transportent,
            qu'il s'agisse de l'achat en magasin ou de services sur mesure,
            comme des voyages ou des événements privés.
          </li>
          <li>
            <strong>La qualité et l'authenticité :</strong> La qualité des
            matériaux, le savoir-faire artisanal et l'authenticité d'une marque
            sont des facteurs de motivation importants.
          </li>
          <li>
            <strong>Les valeurs éthiques et durables :</strong> Une part
            croissante de consommateurs de luxe recherche des marques qui
            partagent des valeurs telles que l’éthique, la durabilité et
            l’engagement social.
          </li>
        </ul>
      </section>

      <section className="bg-black p-6 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Les Comportements d'Achat des Consommateurs de Luxe
        </h2>
        <p className="mb-6">
          Les consommateurs de luxe sont des individus qui recherchent une
          personnalisation maximale dans leurs achats. Cela peut se traduire par
          la création de produits sur mesure ou par la mise à disposition d'un
          service client hyper personnalisé. Les comportements d'achat sont
          souvent influencés par des événements exclusifs, comme des défilés de
          mode, des lancements de produits, ou des collaborations avec des
          célébrités.
        </p>
        <p className="mb-6">
          En outre, ces consommateurs sont de plus en plus enclins à effectuer
          leurs achats en ligne, mais toujours avec la même exigence de service
          haut de gamme. Les marques de luxe doivent donc combiner l'expérience
          physique avec des plateformes numériques qui offrent une expérience
          fluide et haut de gamme.
        </p>
      </section>

      <section className="p-4 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Le Comportement Social et Psychologique
        </h2>
        <p className="mb-6">
          Le consommateur de luxe n'achète pas seulement pour lui-même, il
          achète aussi pour se projeter dans un certain mode de vie. Il est
          motivé par des symboles de réussite et par la quête de l’exclusivité.
          Le luxe, dans ce contexte, devient un moyen de distinguer son identité
          et d’affirmer son appartenance à une classe sociale supérieure.
        </p>
      </section>

      <section className="bg-gradient-to-b from-black to-[#121212] p-6 mb-6">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Les Nouveaux Consommateurs de Luxe
        </h2>
        <p className="mb-6">
          Le secteur du luxe connaît un changement notable avec l’émergence des
          jeunes générations. Les générations Y et Z sont de plus en plus
          présentes dans le marché du luxe, mais elles sont très différentes de
          leurs aînés. Elles privilégient l’authenticité, l’engagement social
          des marques et la transparence.
        </p>
        <p className="mb-6">
          Ce groupe se soucie également de la durabilité des produits et attend
          des marques qu’elles intègrent des pratiques responsables dans leur
          production. Ces consommateurs de luxe s’intéressent aussi davantage à
          l’expérience globale que leur procure la marque, incluant le
          storytelling et l’impact social des produits qu’ils consomment.
        </p>
        <p>
          À la fin de ce module, vous serez capable de comprendre les attentes
          et les comportements des consommateurs de luxe et de créer des
          stratégies qui répondent à leurs désirs.
        </p>
      </section>
      <Footer />
    </div>
  );
};

export default LesConsommateurs;
