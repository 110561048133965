import React, { useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useUser, UserButton } from '@clerk/clerk-react';
import Footer from '../components/Footer';

// Fonction utilitaire pour générer un pourcentage de progression
const generateProgressValues = (count) =>
  Array.from({ length: count }, () => Math.floor(Math.random() * 101));

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Tableau De Bord - LEYZEN™';
  }, []);

  const { user } = useUser();

  const progressValues = useMemo(() => generateProgressValues(4), []);

  const brandSpecializations = [
    {
      id: 'prada',
      name: 'Prada',
      description: 'Stratégies de fidélisation pour Prada.',
      progress: progressValues[1],
    },
    {
      id: 'lvmh',
      name: 'LVMH',
      description: 'Approches spécifiques pour les marques LVMH.',
      progress: progressValues[2],
    },
    {
      id: 'hermes',
      name: 'Hermès',
      description: 'Techniques avancées pour Hermès.',
      progress: progressValues[3],
    },
  ];

  return (
    <div className="min-h-screen bg-zinc-950 text-white flex flex-col">
      {/* En-tête */}
      <header className="p-6 border-b border-gray-800 flex justify-between items-center">
        <div>
          <h1 className="text-4xl font-alientz">Bonjour {user?.firstName},</h1>
          <p className="text-gray-400 text-sm mt-2">
            Prêt à découvrir le luxe et vous spécialiser ?
          </p>
        </div>
        <div className="scale-150">
          <UserButton />
        </div>
      </header>

      {/* Contenu principal */}
      <main className="flex-1 overflow-y-auto p-6">
        {/* Section Tronc Commun */}
        <section className="mb-12">
          <h2 className="text-center text-3xl font-alientz mb-8">
            Au Cœur du Luxe
          </h2>
          <div className="p-6 bg-gradient-to-br from-[#1a1a1a] to-[#282828] rounded-3xl shadow-md hover:shadow-lg hover:shadow-white transition">
            <h3 className="text-xl font-bold mb-2">Introduction au Luxe</h3>
            <p className="text-gray-400 mb-4">
              Explorez l'histoire et l'univers du luxe.
            </p>

            {/* Barre de progression */}
            <div className="relative pt-1">
              <div className="flex mb-2 items-center justify-between">
                <span className="text-sm font-bold">Progression</span>
                <span className="text-xs font-medium">
                  {progressValues[0]}%
                </span>
              </div>
              <div className="w-full bg-gray-700 rounded-full overflow-hidden">
                <div
                  className="bg-white text-xs leading-none py-1 text-center text-black rounded-full transition-all duration-700"
                  style={{ width: `${progressValues[0]}%` }}
                ></div>
              </div>
            </div>

            <Link to="/core/introduction">
              <button className="mt-4 px-4 py-2 border-2 border-white rounded-full hover:bg-white hover:text-black transition">
                Commencer
              </button>
            </Link>
          </div>
        </section>

        {/* Section Spécialisations */}
        <section>
          <h2 className="text-center text-3xl font-alientz mb-8">
            Choisissez une Spécialisation
          </h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
            {brandSpecializations.map((brand) => (
              <div
                key={brand.id}
                className="p-6 bg-gradient-to-br from-[#1a1a1a] to-[#282828] rounded-3xl shadow-md hover:shadow-lg hover:shadow-white transition"
              >
                <h3 className="text-xl font-bold mb-2">{brand.name}</h3>
                <p className="text-gray-400 mb-4">{brand.description}</p>

                {/* Barre de progression pour les spécialisations */}
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <span className="text-sm font-bold">Progression</span>
                    <span className="text-xs font-medium">
                      {brand.progress}%
                    </span>
                  </div>
                  <div className="w-full bg-gray-700 rounded-full overflow-hidden">
                    <div
                      className="bg-white text-xs leading-none py-1 text-center text-black rounded-full transition-all duration-700"
                      style={{ width: `${brand.progress}%` }}
                    ></div>
                  </div>
                </div>

                <button className="mt-4 px-4 py-2 border-2 border-white rounded-full hover:bg-white hover:text-black transition">
                  Découvrir
                </button>
              </div>
            ))}
          </div>
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Dashboard;
