import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import Footer from '../components/Footer';
import instructions from './instructions';
import { useUser } from '@clerk/clerk-react';

const Lumi = () => {
  useEffect(() => {
    document.title = 'Lumi - LEYZEN™';
  }, []);

  const { user } = useUser();
  const [userInput, setUserInput] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatEndRef = useRef(null); // Référence pour auto-scroll

  // Fonction pour obtenir la réponse de Gemini avec gestion de l'animation
  const fetchGeminiResponse = async (contentText) => {
    setLoading(true);

    try {
      const historyContext = messageHistory
        .map(
          (message) =>
            `Étudiant : ${message.userMessage}\nRéponse : ${message.aiResponse}`,
        )
        .join('\n\n');

      const prompt = `
        ${instructions}

        Contexte historique :
        ${historyContext}
        
        nom de famille de l'étudiant : ${user.lastName}
        prénom de l'étudiant : ${user.firstName}

        Message de l'étudiant : ${contentText}
      `;

      const response = await axios.post(
        'https://generativelanguage.googleapis.com/v1beta/models/gemini-1.5-flash:generateContent',
        {
          contents: [
            {
              parts: [{ text: prompt }],
            },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          params: {
            key: process.env.REACT_APP_GEMINI_API_KEY,
          },
        },
      );

      if (
        response.data?.candidates?.length > 0 &&
        response.data.candidates[0]?.content?.parts?.length > 0
      ) {
        const cleanedText = response.data.candidates[0].content.parts[0].text;

        let i = 0;
        const interval = setInterval(() => {
          setMessageHistory((prev) => {
            const newHistory = [...prev];
            const lastMessageIndex = newHistory.length - 1;

            // Mise à jour progressive de la réponse de Lumi
            if (newHistory[lastMessageIndex]?.aiResponse !== undefined) {
              newHistory[lastMessageIndex].aiResponse = cleanedText.substring(
                0,
                i,
              );
            }

            return newHistory;
          });

          i++;

          if (i > cleanedText.length) {
            clearInterval(interval);
            setLoading(false);
          }
        }, 30);
      } else {
        console.error(
          'La réponse de Lumi ne contient pas la structure attendue.',
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Erreur lors de l'appel à Lumi :", error);
      setLoading(false);
    }
  };

  // Envoi d'un message
  const handleSendMessage = () => {
    if (userInput.trim()) {
      setMessageHistory((prev) => [
        ...prev,
        { userMessage: userInput, aiResponse: '' },
      ]);
      fetchGeminiResponse(userInput);
      setUserInput('');
    }
  };

  // Réinitialisation du chat
  const handleNewChat = () => {
    setMessageHistory([]);
  };

  // Fonction pour envoyer un message avec la touche Entrée
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Empêche un retour à la ligne
      handleSendMessage();
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-zinc-950 text-gray-100">
      <div className="module-container max-w-4xl mx-auto p-8 bg-gray-850 w-full mb-16 mt-2 flex-grow">
        <h2 className="text-4xl font-alientz mb-8 text-center text-gradient">
          Posez toutes vos questions à Lumi
        </h2>

        <div className="flex justify-between mb-4">
          <button
            onClick={handleNewChat}
            className="bg-black shadow-sm shadow-white hover:shadow-black text-white font-semibold p-3 rounded-xl shadow-md transition-all duration-400"
            style={{ display: loading ? 'none' : 'inline-block' }}
          >
            Nouveau chat
          </button>
        </div>

        {/* Historique du chat */}
        <div className="chat-history mb-8 p-4 bg-transparent rounded-3xl shadow">
          {messageHistory.map((message, index) => (
            <div key={index} className="mb-4">
              <p className="text-gray-300">
                <span className="font-bold text-indigo-400">Étudiant :</span>{' '}
                {message.userMessage}
              </p>

              <p className="text-gray-200 mt-2">
                <span className="font-bold text-indigo-400">Lumi :</span>{' '}
                <p className="whitespace-normal break-words leading-relaxed">
                  <ReactMarkdown>{message.aiResponse}</ReactMarkdown>
                </p>
              </p>
            </div>
          ))}
          <div ref={chatEndRef}></div>
        </div>

        {/* Section d'entrée */}
        <div className="input-section mb-8">
          <label htmlFor="userInput" className="sr-only">
            Posez votre question
          </label>
          <textarea
            id="userInput"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Posez une question ou demandez une information sur le luxe..."
            className="w-full h-40 p-5 bg-black border border-white rounded-xl focus:outline-none text-gray-300 placeholder-gray-500 transition-all shadow-md font-bold"
          ></textarea>
          <button
            onClick={handleSendMessage}
            disabled={loading}
            className="mt-6 w-full bg-purple-600 hover:bg-purple-800 transition duration-500 enabled:shadow enabled:shadow-white font-alientz text-white p-3 rounded-2xl shadow-md disabled:bg-zinc-950 disabled:animate-pulse transition-all"
          >
            {loading ? "Lumi est entrain d'écrire..." : 'Envoyer'}
          </button>
        </div>

        <div className="instruction mt-8">
          <p className="text-sm text-center text-gray-500 italic">
            Lumi est là pour vous guider dans votre exploration du monde du
            luxe. Posez-lui toutes vos questions.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Lumi;
