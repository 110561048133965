import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';

const NextButton = ({ onClick, isLastQuestion }) => {
  return (
    <motion.button
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      onClick={onClick}
      className="w-full max-w-2xl mt-6 bg-purple-600 font-bold text-white p-4 rounded-xl flex items-center justify-center gap-2 hover:bg-purple-700 transition-all duration-400 hover:scale-105"
    >
      {isLastQuestion ? 'Voir le résultat' : 'Question suivante'}
      <ChevronRight className="w-5 h-5" />
    </motion.button>
  );
};

export default NextButton;
