import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import SatinImage from '../assets/satin.avif';

function SignInPage() {
  return (
    <section className="relative items-center text-center min-h-screen bg-[#161616] flex flex-col">
      {/* Contenu devant l'image */}
      <h1 className="text-3xl mt-4 mb-5 font-alientz z-20">
        Connexion à mon espace étudiant
      </h1>
      <div className="z-20">
        <SignIn />
      </div>

      {/* Fond noir semi-transparent */}
      <div className="absolute inset-0 bg-black opacity-10 z-10" />

      {/* Image de fond */}
      <img
        src={SatinImage}
        alt="Luxury background"
        className="absolute inset-0 w-full h-full object-cover z-0"
      />
    </section>
  );
}

export default SignInPage;
