import { useEffect } from 'react';
import CoreLuxeNav from '../../components/CoreLuxeNav';
import Footer from '../../components/Footer';

const ServiceClient = () => {
  useEffect(() => {
    document.title = 'Service Client - LEYZEN™';
  }, []);

  return (
    <div>
      <CoreLuxeNav />
      <section className="mb-4 p-8">
        <h1 className="text-4xl font-alientz text-center">
          Service Client de Luxe
        </h1>
        <p className="mt-6">
          Le service client est un élément central de l'expérience de luxe. Ce
          module vous apprendra à offrir un service exceptionnel qui dépasse les
          attentes des clients les plus exigeants.
        </p>
      </section>

      <section className="bg-black mb-6 p-8">
        <h2 className="text-2xl font-alientz text-center mb-4">
          Les Fondements du Service Client de Luxe
        </h2>
        <p>
          Le service client dans l'univers du luxe repose sur des principes
          fondamentaux : l'attention au détail, la personnalisation de
          l'expérience, et la réactivité immédiate. Offrir un service de luxe,
          c'est s'assurer que chaque client se sent unique, apprécié et respecté
          à chaque étape de son parcours d'achat.
          <br />
          <br />
          Contrairement aux services client classiques, le service client de
          luxe ne se contente pas de résoudre des problèmes ; il anticipe les
          besoins du client et les satisfait avant même qu'ils ne soient
          exprimés. Cela peut inclure des services comme des réservations
          spéciales, des invitations à des événements exclusifs ou encore des
          cadeaux sur mesure.
        </p>
      </section>

      <h2 className="text-2xl font-alientz text-center mb-4">
        Personnalisation et Anticipation des Besoins
      </h2>
      <p className="mb-6 p-8">
        La personnalisation est au cœur du service client de luxe. Cela signifie
        que chaque interaction avec la marque doit être unique, et que les
        agents doivent être formés pour offrir un service sur mesure. Cela
        inclut la capacité de se souvenir des préférences de chaque client, de
        les surprendre par des touches personnelles, et d'anticiper leurs
        désirs.
      </p>

      <section className="bg-black mb-6 p-8">
        <h2 className="text-2xl font-alientz text-center mb-4">
          L'Importance de la Communication
        </h2>
        <p>
          Une communication claire et respectueuse est essentielle dans le
          service client de luxe. Les clients doivent se sentir écoutés, et
          leurs préoccupations doivent être traitées avec une grande importance.
          Le ton, la manière de répondre et la façon dont on prend soin du
          client sont des éléments clés.
        </p>
      </section>

      <h2 className="text-2xl font-alientz text-center mb-4">
        Gérer les Réclamations et les Insatisfactions
      </h2>
      <p className="mb-6 p-8">
        Bien que le luxe vise à éviter les réclamations, elles peuvent parfois
        survenir. Dans ces moments, l'art du service client réside dans la
        capacité à gérer les situations de crise avec calme et efficacité. Un
        service client de luxe doit résoudre les problèmes de manière proactive,
        trouver des solutions créatives, et toujours maintenir la réputation de
        la marque.
        <br />
        <br />À la fin de ce module, vous serez prêt à offrir un service client
        d'exception, qui renforcera la fidélité des clients et l'image de marque
        dans l'univers du luxe.
      </p>
      <Footer />
    </div>
  );
};

export default ServiceClient;
