import React from 'react';
import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import { frFR } from '@clerk/localizations';
import { dark } from '@clerk/themes';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import SignInPage from './pages/SignInPage';
import Dashboard from './pages/Dashboard';
import {
  Introduction,
  LesMarques,
  LesConsommateurs,
  ServiceClient,
  Tendances,
} from './modules/core';
import Quiz from './modules/core/Quiz';
import Lumi from './pages/Lumi';
import Help from './pages/Help';
import NotFoundPage from './pages/NotFoundPage';
import clerkConfig from './clerk/clerkConfig';
import { Analytics } from '@vercel/analytics/react';
import ScrollToTop from './components/ScrollToTop';

// Composant pour les transitions de page
const PageTransition = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};

const AppContent = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <ScrollToTop />
      {/* Gestion des routes avec animation */}
      <Routes location={location} key={location.pathname}>
        {/* Page de connexion sur la racine */}
        <Route
          path="/"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <RedirectToDashboard />
                </SignedIn>
                <SignedOut>
                  <SignInPage />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        {/* Tableau de bord spécifique à chaque utilisateur */}
        <Route
          path="/dashboard"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Dashboard />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/core/introduction"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Introduction />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/core/les-marques"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <LesMarques />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/core/les-consommateurs"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <LesConsommateurs />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/core/service-client"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <ServiceClient />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/core/tendances"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Tendances />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/core/quiz"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Quiz />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />

        <Route
          path="/lumi"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Lumi />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        <Route
          path="/help"
          element={
            <PageTransition>
              <>
                <SignedIn>
                  <Help />
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            </PageTransition>
          }
        />
        {/* Gestion des routes non définies */}
        <Route
          path="*"
          element={
            <PageTransition>
              <NotFoundPage />
            </PageTransition>
          }
        />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  return (
    <ClerkProvider
      publishableKey={clerkConfig.api}
      appearance={{ baseTheme: dark }}
      localization={frFR}
      navigate={(to) => (window.location.href = to)}
    >
      <Router>
        <AppContent />
        <Analytics />
      </Router>
    </ClerkProvider>
  );
};

// Composant séparé pour gérer la redirection avec useUser
const RedirectToDashboard = () => {
  return <Navigate to={`/dashboard`} replace />;
};

const RedirectToSignIn = () => {
  return <Navigate to="/" replace />;
};

export default App;
