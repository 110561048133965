import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import QuizCard from './QuizCard';
import ScoreCard from './ScoreCard';
import ProgressBar from './ProgressBar';
import NextButton from './NextButton';
import { questions } from './questions';
import Footer from '../../../components/Footer';

const Quiz = () => {
  const [state, setState] = useState({
    currentQuestionIndex: 0,
    score: 0,
    selectedAnswer: null,
    isAnswered: false,
    showScore: false,
  });

  const currentQuestion = questions[state.currentQuestionIndex];

  const handleAnswerSelect = (answerIndex) => {
    setState((prev) => ({
      ...prev,
      selectedAnswer: answerIndex,
      isAnswered: true,
      score:
        answerIndex === currentQuestion.correctAnswer
          ? prev.score + 1
          : prev.score,
    }));
  };

  const handleNextQuestion = () => {
    if (state.currentQuestionIndex === questions.length - 1) {
      setState((prev) => ({ ...prev, showScore: true }));
    } else {
      setState((prev) => ({
        ...prev,
        currentQuestionIndex: prev.currentQuestionIndex + 1,
        selectedAnswer: null,
        isAnswered: false,
      }));
    }
  };

  const handleRestart = () => {
    setState({
      currentQuestionIndex: 0,
      score: 0,
      selectedAnswer: null,
      isAnswered: false,
      showScore: false,
    });
  };

  return (
    <div>
      <div className="min-h-screen bg-zinc-950 flex flex-col items-center justify-center p-4">
        {/* Bouton Retour */}
        <Link
          to="/core/tendances"
          className="absolute top-4 left-4 bg-black text-white font-bold shadow-sm shadow-white px-4 py-2 rounded-2xl shadow-lg hover:bg-[#161616] transition"
        >
          Retour
        </Link>

        <h1 className="text-4xl font-alientz text-white text-center mb-12 mt-8">
          Quiz sur le Luxe
        </h1>

        <div className="w-full max-w-4xl flex flex-col items-center">
          <AnimatePresence mode="wait">
            {state.showScore ? (
              <div className="w-full flex justify-center">
                <ScoreCard
                  key="score"
                  score={state.score}
                  totalQuestions={questions.length}
                  onRestart={handleRestart}
                />
              </div>
            ) : (
              <div key="quiz" className="w-full flex flex-col items-center">
                <ProgressBar
                  currentQuestion={state.currentQuestionIndex}
                  totalQuestions={questions.length}
                  score={state.score}
                />

                <QuizCard
                  question={currentQuestion}
                  selectedAnswer={state.selectedAnswer}
                  onSelectAnswer={handleAnswerSelect}
                  isAnswered={state.isAnswered}
                  showCorrectAnswer={state.isAnswered}
                />

                {state.isAnswered && (
                  <div className="mb-10 w-full flex justify-center">
                    <NextButton
                      onClick={handleNextQuestion}
                      isLastQuestion={
                        state.currentQuestionIndex === questions.length - 1
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </AnimatePresence>
        </div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default Quiz;
