import { useEffect } from 'react';
import CoreLuxeNav from '../../components/CoreLuxeNav';
import Footer from '../../components/Footer';

const Introduction = () => {
  useEffect(() => {
    document.title = 'Introduction - LEYZEN™';
  }, []);

  return (
    <div>
      <CoreLuxeNav />
      <div>
        <h1 className="text-3xl text-center font-alientz mb-6">
          Introduction au Luxe
        </h1>
        <p className="mb-6 p-6">
          Le luxe est plus qu'un simple produit ou service. Il est une
          expérience, une aspiration, un reflet de l'excellence. Ce module va
          vous plonger dans l'univers fascinant du luxe, son histoire, ses
          valeurs et son impact sur les industries modernes.
          <br />
          <br />
          Le luxe a traversé les âges, des objets rares utilisés par les rois et
          reines aux marques mondiales modernes. Ce secteur a toujours été lié à
          des valeurs comme l'exclusivité, la rareté, la qualité, et
          l'artisanat. Mais il n'est pas seulement lié à des biens matériels ;
          le luxe est aussi une expérience, une forme de service et d'attention,
          souvent réservée à une élite.
        </p>

        {/* Section 1: Les origines du luxe */}
        <div className="bg-black p-6 mb-6">
          <h2 className="text-2xl text-center font-alientz text-white mb-4">
            Les origines du luxe
          </h2>
          <p className="mb-6 text-white">
            Les premières formes de luxe remontent à l'Antiquité, lorsque les
            empires utilisaient des objets précieux pour symboliser leur pouvoir
            et leur statut. Des civilisations comme les Égyptiens, les Romains,
            et les Perses utilisaient des pierres précieuses, des métaux rares,
            et des tissus finement travaillés pour montrer leur richesse.
          </p>
          <p className="mb-6 text-white">
            Dans le contexte moderne, les grandes maisons de couture et les
            marques de luxe comme Louis Vuitton, Chanel, et Hermès ont redéfini
            ce concept de luxe en le rendant non seulement exclusif, mais aussi
            un symbole de statut et d'appartenance à une classe sociale élevée.
          </p>
        </div>

        {/* Section 2: Les caractéristiques du luxe moderne */}
        <h2 className="text-2xl text-center font-alientz mb-4">
          Les caractéristiques du luxe moderne
        </h2>
        <div className="p-6">
          <ul className="list-disc pl-6 mb-6">
            <li>
              <strong>Exclusivité :</strong> Le luxe est souvent associé à des
              produits ou services difficiles à obtenir, souvent en raison de
              leur prix ou de leur disponibilité limitée.
            </li>
            <li>
              <strong>Artisanat et qualité :</strong> Le luxe se distingue par
              une attention extrême aux détails, un savoir-faire exceptionnel,
              et des matériaux de la plus haute qualité.
            </li>
            <li>
              <strong>Expérience client :</strong> Les marques de luxe offrent
              une expérience globale qui dépasse le simple achat. Cela inclut un
              service client personnalisé et une immersion totale dans l'univers
              de la marque.
            </li>
            <li>
              <strong>Émotion et aspiration :</strong> L'achat de produits de
              luxe est souvent motivé par le désir de se démarquer ou
              d'atteindre un idéal social et culturel.
            </li>
          </ul>
        </div>

        {/* Section 3: Les grandes marques du luxe */}
        <div className="bg-black p-6 mb-6">
          <h2 className="text-2xl text-center font-alientz text-white mb-4">
            Les grandes marques du luxe
          </h2>
          <p className="mb-6 text-white">
            Les marques de luxe telles que <strong>Louis Vuitton</strong>,{' '}
            <strong>Chanel</strong>, <strong>Hermès</strong>,{' '}
            <strong>Gucci</strong> et <strong>Rolex</strong> dominent le secteur
            grâce à leur capacité à maintenir une image de prestige tout en
            restant accessibles à une clientèle de niche. Chaque marque a son
            propre héritage et son propre langage, ce qui permet de se connecter
            profondément avec ses clients.
          </p>
          <p className="mb-6 text-white">
            Par exemple, <strong>Louis Vuitton</strong> a créé un univers autour
            de ses sacs iconiques, tandis que <strong>Chanel</strong> a basé son
            succès sur l’élégance intemporelle et la sophistication de ses
            créations. Ces marques sont des maîtres dans l’art de raconter une
            histoire, créant une connexion émotionnelle avec leurs
            consommateurs.
          </p>
        </div>

        {/* Section 4: L'impact du luxe sur les tendances mondiales */}
        <h2 className="text-2xl text-center font-alientz mb-4">
          L'impact du luxe sur les tendances mondiales
        </h2>
        <p className="mb-6 p-6">
          Le luxe influence non seulement les consommateurs, mais aussi d'autres
          industries comme la mode, l'architecture, la gastronomie,
          l'automobile, et même le design technologique. Les marques de luxe ont
          le pouvoir de définir des tendances qui sont ensuite adaptées par le
          grand public.
          <br />
          <br />
          Un exemple frappant est l'influence de la mode de luxe sur le
          streetwear. Des marques comme <strong>Balenciaga</strong> ou{' '}
          <strong>Off-White</strong> ont contribué à démocratiser le luxe dans
          des segments de marché plus jeunes et plus diversifiés.
          <br />
          <br />À la fin de ce module, vous aurez une compréhension solide du
          luxe, de son histoire, de ses valeurs, et de son rôle crucial dans les
          tendances de consommation mondiales.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Introduction;
