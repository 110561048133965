import { useState } from 'react';
import { Link } from 'react-router-dom';

const CoreLuxeNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-black py-4 font-alientz rounded-b-3xl shadow shadow-white mb-8">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header avec le bouton burger */}
        <div className="flex items-center justify-between">
          <Link to="/dashboard">
            <h1 className="ml-4 text-white text-2xl lg:hidden">HOME</h1>
          </Link>
          <button
            className="text-white lg:hidden"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 5.25h16.5m-16.5 7.5h16.5m-16.5 7.5h16.5"
              />
            </svg>
          </button>
        </div>

        {/* Menu principal */}
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } lg:flex lg:items-center lg:justify-center lg:space-x-4 mt-4 lg:mt-0`}
        >
          <Link
            to="/core/introduction"
            className="block px-4 py-2 text-lg text-white hover:bg-[#333333] transition duration-300 rounded-xl"
          >
            Introduction
          </Link>
          <Link
            to="/core/les-marques"
            className="block px-4 py-2 text-lg text-white hover:bg-[#333333] transition duration-300 rounded-xl"
          >
            Les Marques
          </Link>
          <Link
            to="/core/les-consommateurs"
            className="block px-4 py-2 text-lg text-white hover:bg-[#333333] transition duration-300 rounded-xl"
          >
            Les Consommateurs
          </Link>
          <Link
            to="/core/service-client"
            className="block px-4 py-2 text-lg text-white hover:bg-[#333333] transition duration-300 rounded-xl"
          >
            Service Client
          </Link>
          <Link
            to="/core/tendances"
            className="block px-4 py-2 text-lg text-white hover:bg-[#333333] transition duration-300 rounded-xl"
          >
            Tendances
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default CoreLuxeNav;
