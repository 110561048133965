import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-black w-full rounded-t-3xl py-6 px-4 text-gray-400 border-t border-white">
      {/* Conteneur principal avec flex-wrap pour les petits écrans */}
      <div className="flex flex-row justify-center items-left gap-6">
        <Link
          to="/dashboard"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          🏠 Accueil
        </Link>
        <Link
          to="/lumi"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          💬 Lumi
        </Link>
        <Link
          to="/help"
          className="hover:text-white flex items-center gap-2 duration-300"
        >
          ❓ Centre d'aide
        </Link>
      </div>

      {/* Texte en dessous */}
      <p className="mt-4 text-xs text-white opacity-90 font-bold text-center">
        {new Date().getFullYear()} LEYZEN&#8482;. Tous droits réservés.
      </p>
    </footer>
  );
}

export default Footer;
